import numeral from 'numeral'

function numToTime(num) {
  num = parseInt(num);
  return numeral(num).format('00:00:00');
}

export { 
  numToTime 
}

